import React from "react";
import classes from "./Brands.module.scss";
import naar from "../../assets/Brands/naar.png";
import zen1 from "../../assets/Brands/zengaz1.png";
// import zen2 from "../../assets/Brands/zengaz2.png";
// import brand1 from "../../../assets/Brands/brand1.png";
// import brand2 from "../../../assets/Brands/brand2.png";
import youtube from "../../assets/Socials/Youtube.png";
import facebook from "../../assets/Socials/Facebook.png";
import instagram from "../../assets/Socials/Instagram.png";
import twitter from "../../assets/Socials/TikTok.png";

interface IBrandsProps {}
const Brands: React.FC<IBrandsProps> = () => {
    const [divHeight, setDivHeight] = React.useState<number>(0);
    const textRef = React.useRef<HTMLDivElement>(null);

    const updateHeight = () => {
        if (textRef.current) {
            setDivHeight(textRef.current.clientHeight); // or use offsetHeight for including padding
        }
    };

    React.useEffect(() => {
        // Update height on component mount
        updateHeight();

        // Add resize event listener
        window.addEventListener("resize", updateHeight);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);

    return (
        <section className={classes.brands__container}>
            <div className={classes.col}>
                <div className={classes.content__container}>
                    <img className={classes.naar__image} src={naar} alt="naar" />
                    <div className={`${classes.hover__content}`}>
                        {/* <div className={classes.explore}>EXPLORE</div> */}
                        <div className={classes.visit__site}>
                            <a href="https://naarofficial.com" target="blank">
                                VISIT SITE
                            </a>
                        </div>
                        <div className={classes.socials}>
                            <div className={classes.bluebg}>
                                <a href="https://www.instagram.com/naar.official/" target="_blank">
                                    <img src={instagram} alt="instagram" />
                                </a>
                            </div>
                            <div className={classes.bluebg}>
                                <a href="https://www.facebook.com/profile.php?id=61555318518001" target="_blank">
                                    <img src={facebook} alt="facebook" />
                                </a>
                            </div>
                            <div className={classes.bluebg}>
                                <a href="https://www.youtube.com/@naar.official/shorts" target="_blank">
                                    <img src={youtube} alt="youtube" />
                                </a>
                            </div>
                            <div className={classes.bluebg}>
                                <a href="https://www.tiktok.com/@naar.official" target="_blank">
                                    <img src={twitter} alt="twitter" />
                                </a>
                            </div>
                        </div>
                        <div className={classes.description} style={{ height: `${divHeight}px` }}>
                            NAAR is a brand-new concept in the cannabis-friendly world that is innovative both in design & function.
                        </div>
                        {/* <div className={classes.visit__site}>
              <a href="https://naarofficial.com" target="blank">
                VISIT SITE
              </a>
            </div> */}
                    </div>
                </div>
            </div>
            <div className={classes.col}>
                <div className={`${classes.content__container}`}>
                    <div className={classes.imgs__container}>
                        <img className="" src={zen1} alt="naar" />
                    </div>
                    <div className={`${classes.hover__content}`}>
                        {/* <div className={classes.explore}>EXPLORE</div> */}
                        <div className={classes.visit__site}>
                            <a href="https://zengaz.com" target="_blank">
                                VISIT SITE
                            </a>
                        </div>
                        <div className={classes.socials}>
                            <div className={classes.fuschiabg}>
                                <a href="https://www.instagram.com/zengaz.worldwide/" target="_blank">
                                    <img src={instagram} alt="instagram" />
                                </a>
                            </div>
                            <div className={classes.fuschiabg}>
                                <a href="https://www.facebook.com/zengaz.worldwide/" target="_blank">
                                    <img src={facebook} alt="facebook" />
                                </a>
                            </div>
                            <div className={classes.fuschiabg}>
                                <a href="https://www.youtube.com/channel/UCPrcudyPX5_IjpoSztTMneA" target="_blank">
                                    <img src={youtube} alt="youtube" />
                                </a>
                            </div>
                            <div className={classes.fuschiabg}>
                                <a href="https://www.tiktok.com/@zengaz.worldwide?lang=en" target="_blank">
                                    <img src={twitter} alt="twitter" />
                                </a>
                            </div>
                        </div>
                        <div className={classes.description} ref={textRef}>
                            Zengaz is an internationally leading brand, spread across 70+ countries and partnered with several global brands including Imperial Brands, FIFA, The Bulldog & La Aurora.
                        </div>
                        {/* <div className={classes.visit__site}>
              <a href="https://zengaz.shop" target="blank">
                VISIT SITE
              </a>
            </div> */}
                    </div>
                    {/* <img className="" src={zen2} alt="naar" /> */}
                </div>
            </div>
        </section>
    );
};

export default Brands;
